import React, { useState, useRef, useEffect, useMemo } from "react";
import { Product } from "../types/Product";
import { css } from "styled-components";
import { Spacing, Row } from "../helpers/layout";
import { Label } from "../typography";
import { useWishlist } from "../context/WishlistContext";
import { useRecentlyViewed } from "../context/RecentlyViewedContext";
import { ProductThumb } from "../components/ProductThumb";
import { MasonryGrid } from "../components/MasonryGrid";
import { useResponsive } from "../context/ResponsiveContext";
import { Button } from "../components/Button";
import CheckIcon from "../icons/CheckIcon";
import { Colors } from "../branding";
import { PlainButton } from "../components/PlainButton";
import { useComparison } from "../context/ComparisonContext";
import { MobileModal } from "./MobileModal";
import { Search } from "../components/Search";
import ArrowUp from "../icons/ArrowUp";
import throttle from "lodash/throttle";

type Section = "wishlist" | "recently-viewed" | "comparison" | "search";

const sectionTitles = {
  wishlist: {
    mobile: "Wishlist",
    desktop: "Wishlist",
  },
  "recently-viewed": {
    mobile: "Recent",
    desktop: "Recently Viewed",
  },
  comparison: {
    mobile: "Comparison",
    desktop: "Comparison",
  },
  search: {
    mobile: "Search",
    desktop: "Search",
  },
};

export function ProductSelectorModal(props: {
  visible: boolean;
  onClose: () => void;
  onSelect: (product: Product) => void;
  sections: Section[];
  defaultSection: Section;
}) {
  const { sections } = props;
  const { wishlist } = useWishlist();
  const { isMobile, isTablet, is13inch } = useResponsive();
  const { recentlyViewed } = useRecentlyViewed();
  const scrollContainer = useRef<HTMLDivElement>(null);
  const [initialized, setInitialized] = useState(false);
  const { comparison } = useComparison();
  const [selected, setSelected] = useState<Product | null>(null);
  const [type, setType] = useState<Section>(props.defaultSection);
  const [showArrow, setShowArrow] = useState(false);

  const columns = useMemo(() => {
    if (isMobile) {
      return 1;
    }
    if (isTablet || is13inch) {
      return 2;
    }

    return 3;
  }, [isMobile, isTablet, is13inch]);

  useEffect(() => {
    if (props.visible) {
      setInitialized(true);
    } else {
      setInitialized(false);
    }

    return () => {
      setInitialized(false);
    };
  }, [props.visible]);

  useEffect(() => {
    const container = scrollContainer.current;
    const handleScroll = throttle(() => {
      if (!container) return;

      const clientHeight = window.innerHeight;
      const scrollTop = container.scrollTop;
      const reachedPoint = scrollTop > clientHeight;

      if (reachedPoint) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    }, 200);

    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [initialized]);

  const items =
    type === "wishlist"
      ? wishlist.items
      : type === "recently-viewed"
      ? recentlyViewed.items
      : type === "comparison"
      ? comparison.items
      : [];
  const products = items.reduce((products: Product[], item) => {
    const productExist = products.find((p) => p.id === item.product.id);

    if (!productExist) {
      return [...products, item.product];
    }

    return products;
  }, []);

  const selector = (
    <Row gutter={isMobile ? Spacing.s : Spacing.m}>
      {sections.map((section, index) => {
        const title = isMobile
          ? sectionTitles[section].mobile
          : sectionTitles[section].desktop;

        return (
          <React.Fragment key={section}>
            <Label
              bold={type === section}
              onClick={() => {
                setType(section);
              }}
              css={css`
                cursor: pointer;

                ${isMobile &&
                css`
                  font-size: 11px;
                `}
              `}
            >
              {title}
            </Label>
            {index < sections.length - 1 && <Label>/</Label>}
          </React.Fragment>
        );
      })}
    </Row>
  );

  const bottom = (
    <div
      css={css`
        position: relative;
      `}
    >
      <Row justify="space-between">
        <div>
          <Button
            type="button"
            label="Cancel"
            onClick={() => {
              props.onClose();
            }}
          />
        </div>
        <div>
          <Button
            type="button"
            label="Select"
            disabled={!selected}
            onClick={() => {
              props.onSelect(selected!);
              props.onClose();
            }}
          />
        </div>
        <div
          css={css`
            position: absolute;
            width: 40px;
            height: 0;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            text-align: center;
            transition: height 300ms;
            cursor: pointer;
            overflow: hidden;

            ${showArrow &&
            css`
              height: 25px;
            `}

            svg {
              fill: ${Colors.black};
            }
          `}
          onClick={() => {
            if (scrollContainer.current) {
              scrollContainer.current.scrollTo({ top: 0, behavior: "smooth" });
            }
          }}
        >
          <ArrowUp />
        </div>
      </Row>
    </div>
  );

  const selectProduct = (product: Product) => {
    if (isMobile) {
      setSelected(product);
    } else {
      props.onSelect(product);
      props.onClose();
    }
  };

  return (
    <MobileModal
      title={"Select Product From"}
      visible={props.visible}
      onClose={props.onClose}
      bottom={bottom}
      actions={selector}
      bodyRef={scrollContainer}
    >
      <div
        css={css`
          width: 100%;
          box-sizing: border-box;
          background: ${Colors.white};
          color: ${Colors.black};

          ${!isMobile &&
          css`
            padding: ${Spacing.xxl};
          `}
        `}
      >
        <div
          css={css`
            padding-right: 10px;
            box-sizing: border-box;
          `}
        >
          {type === "search" ? (
            initialized ? (
              <Search
                onSelect={(product) => {
                  selectProduct(product);
                }}
                selected={selected}
                scrollContainer={scrollContainer.current}
                isSticky={false}
              />
            ) : null
          ) : products.length > 0 ? (
            <MasonryGrid
              columns={columns}
              items={products}
              margin={isMobile ? 5 : 2}
            >
              {(product, index) => {
                const isActive = selected && selected.id === product.id;

                return (
                  <div
                    className="grid-item"
                    css={css`
                      position: relative;
                      margin-bottom: 5%;
                      cursor: pointer;
                    `}
                    key={`masonry-item--${index}`}
                  >
                    <ProductThumb
                      product={product}
                      onClick={() => {
                        selectProduct(product);
                      }}
                    />
                    {isActive && (
                      <span
                        css={css`
                          position: absolute;
                          right: -6px;
                          top: -6px;
                        `}
                      >
                        <CheckIcon />
                      </span>
                    )}
                  </div>
                );
              }}
            </MasonryGrid>
          ) : (
            <div
              css={css`
                text-align: center;
              `}
            >
              <PlainButton
                underlined
                hoverEffect
                onClick={() => {
                  setType("search");
                }}
              >
                Find Products Here
              </PlainButton>
            </div>
          )}
        </div>
      </div>
    </MobileModal>
  );
}
