import React, { useState } from "react";
import { BaseModal } from "../modals/BaseModal";
import { PrintConfigurator } from "../components/PrintConfigurator";
import { Product } from "../types/Product";
import { css } from "styled-components";
import { ProductOption } from "../types/ProductOption";
import { ProductSize } from "../types/Product";
import { Button } from "../components/Button";
import { Row, Column, Spacing } from "../helpers/layout";
import { TotalPrice, TotalLabel } from "../typography";
import { calculateOrderItem } from "../helpers/product";
import { Price } from "../components/Price";
import { useUserState } from "../context/UserContext";
import { Colors } from "../branding";
import { PrintDimensions } from "../components/PrintDimensions";
import { useCommonData } from "../context/CommonDataContext";
import { PlainButton } from "../components/PlainButton";
import { useModal } from "../context/ModalContext";
import { MobileModal } from "./MobileModal";
import { useResponsive } from "../context/ResponsiveContext";

export function PrintConfiguratorModal(props: {
  product: Product;
  selected: {
    size: ProductSize;
    options: ProductOption[];
  };
  visible: boolean;
  onConfigure: (size: ProductSize, options: ProductOption[]) => Promise<void>;
  onClose: () => void;
  disablePrices?: boolean;
  disableSizeSelection?: boolean;
}) {
  const { isAuthenticated } = useUserState();
  const { settings } = useCommonData();
  const { showModal } = useModal();
  const { isMobile } = useResponsive();
  const [size, setSize] = useState<ProductSize>(props.selected.size);
  const [options, setOptions] = useState<ProductOption[]>(
    props.selected.options
  );

  const ModalComponent = isMobile ? MobileModal : BaseModal;

  const calculated = calculateOrderItem(
    {
      product: props.product,
      size,
      options,
    },
    settings
  );

  const bottom = (
    <Row>
      <div
        css={css`
          flex: 1 0 10%;
        `}
      >
        {!props.disablePrices && (
          <Row align="center">
            <TotalLabel>Total: </TotalLabel>
            <TotalPrice>
              <Price
                price={calculated.subtotal}
                discount={calculated.discount}
              />
            </TotalPrice>
          </Row>
        )}
      </div>
      <div
        css={css`
          flex: 0 0 auto;
        `}
      >
        <Button
          type="button"
          label="Update"
          onClick={async () => {
            await props.onConfigure(size, options);
            props.onClose();
          }}
        />
      </div>
    </Row>
  );

  return (
    <React.Fragment>
      <ModalComponent
        title="Configure"
        small
        visible={props.visible}
        onClose={props.onClose}
        bottom={bottom}
      >
        <div
          css={css`
            padding: ${Spacing.m};
            background: ${Colors.white};
            color: ${Colors.black};
          `}
        >
          <Column gutter={Spacing.l}>
            <PrintConfigurator
              product={props.product}
              selected={{
                size,
                options,
              }}
              onSelectOptions={(options) => setOptions(options)}
              onSelectSize={(size) => setSize(size)}
              disablePrices={props.disablePrices || !isAuthenticated}
              disableSizeSelection={props.disableSizeSelection}
            />
            {!props.disableSizeSelection && (
              <PrintDimensions
                calculated={calculated}
                selectedOptions={options}
              />
            )}
            {!isAuthenticated && (
              <div
                css={css`
                  position: relative;
                  width: 100%;
                  box-sizing: border-box;
                  padding: ${Spacing.m};
                  background: ${Colors.very_light_grey};
                `}
              >
                <div
                  css={css`
                    flex: 1 0 10%;
                    font-size: 10px;
                    letter-spacing: 0.06em;
                    text-transform: uppercase;

                    button {
                      margin-left: 2px;
                      letter-spacing: inherit;
                    }
                  `}
                >
                  To view pricing, please{" "}
                  <PlainButton
                    hoverEffect
                    onClick={() => {
                      showModal("login", {
                        type: "product",
                      });
                    }}
                  >
                    Login / Register
                  </PlainButton>
                </div>
              </div>
            )}
            {!isMobile && bottom}
          </Column>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
}
